
import "./radio-v1"
import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class DeliverySelectV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

delivery_options = []
  mount() {
    this.delivery_options = [
      {
        type: 'standard',
        label: 'Standard',
        delivery_range: {
          min: 5,
          max: 10,
          unit: 'day',
        },
        price: 'Free',
        price_cents: 0,
        currency: '$',
      }]
    this.render()
    this.element.addEventListener('change', (evt) => {
      this.selected_option = evt.target.value
    })
  }


    remove() {
      this.element.innerHTML = '';
    }
    render(initializeChildrenInstances = false) {
      const is_server = true;
      const delivery_options = this.delivery_options ?? [] ;
const selected_option = this.selected_option ?? "standard" ;

    const label = this.element.getAttribute('data-param-label');
  
      let html = '';
      {
        html += `<div class="elCheckoutSectionLabel">${label}</div><div class="elDeliverySelectOptionsContainer">`
        const c0 = delivery_options
        const fl1 = new CF2ForloopDrop(c0.length)
        for (const delivery_option of c0) {
          const forloop = fl1
          html += `<div class="elDeliverySelectOption"><span class="elDeliverySelectInput"><div data-page-element="Radio/V1" class="elRadioWrapper id-Radio/V1" data-liquid-replace="item" data-checked="{{selected_option == delivery_option.type}}" data-trigger-click-on-wrapper="false"><label class="elRadioLabel"><input type="radio" value="{{delivery_option.type}}" name="{{delivery_option}}" checked class="elRadioInput"/><div class="elRadio"><div class="elRadioIcon"></div></div><span class="elRadioText"></span></label></div><label for="delivery_option" class="elLabel"><span>${delivery_option.label}</span><span class="elDeliverySelectRange">`
          const range = delivery_option.delivery_range;
          html += `${range.min} - ${range.max}`
          if (range.unit == "day") {
            html += ` Business Days `
          }
          html += `</span></label></span><span class="elDeliverySelectPrice">${delivery_option.price}</span></div>`
          forloop.next();
        }
        html += `</div>`

      }

      this.element.innerHTML = html;

      if (initializeChildrenInstances) {
        CF2Component.hydrateTree(this.element);
      }
    }


}

registerComponent('DeliverySelect/V1', DeliverySelectV1)
window["DeliverySelectV1"] = DeliverySelectV1

